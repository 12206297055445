import type {
  CategoryList,
  CategoryListResponse,
  ProductCarouselResponse,
  Promotion,
  PromotionProductCarousel,
} from '@/types'
import {
  type BannerList,
  type BannerResponse,
  type BestSellerResponse,
  type FlashSale,
  type FlashSaleResponse,
  type Post,
  type PostResponse,
  type ProductDetail,
  type ProductDetailResponse,
  type PromotionsResponse,
} from '@/types'
import orderBy from 'lodash/orderBy'

export const toPromotions = (res: PromotionsResponse): Promotion | null => {
  const data = res.data[0]
  if (!data) return null
  return data
}

export const toProductTrending = (
  res: PromotionsResponse
): Promotion | null => {
  const data = res.data[0]
  if (!data) return null
  data.products = orderBy(data.products, 'order', 'asc')
  return data
}

export const toProductForYou = (res: PromotionsResponse): Promotion | null => {
  const data = res.data[0]
  if (!data) return null
  return data
}

export const toBestSeller = (res: BestSellerResponse): Promotion | null => {
  const data = res.data[0]
  if (!data) return null
  return data
}

export const toFlashSales = (res: FlashSaleResponse): FlashSale | null => {
  const data = res.data[0]
  if (!data) {
    return null
  }
  data.products = orderBy(data.products, 'order', 'asc')
  return data
}

export const toFlashSale = (data: FlashSale): FlashSale | null => {
  if (!data) {
    return null
  }
  data.products = orderBy(data.products, 'order', 'asc')
  return data
}

export const toPosts = (res: PostResponse): Post[] | null => {
  return res.data
}
export const toBanner = (res: BannerResponse): BannerList[] | null => {
  return res.data
}

export const toCategoryList = (res: CategoryListResponse): CategoryList[] => {
  return res.data
}

export const toProductDetail = (
  res: ProductDetailResponse
): ProductDetail | null => {
  if (!res) return null
  return res.data
}

export const toHomepageProductCarousel = (
  res: ProductCarouselResponse
): PromotionProductCarousel[] | null => {
  const data = res.data
  if (!data) return null
  return data
}
