import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const ProductCardSkeleton = () => {
  return (
    <div className='productCardSkeleton relative '>
      <div className='itemProducts relative h-full items-center overflow-hidden rounded '>
        <div className='image-thumb relative block w-full text-[0px]'>
          <Skeleton
            duration={0.9}
            className=' w-full pt-[100%] text-[0px] block'
            inline
          />
        </div>
        <div className='w-full text-left'>
          <Skeleton duration={0.9} height={28} />
          <div className='product-card price-wrapper w-full'>
            <Skeleton duration={0.9} height={17} />
            <Skeleton duration={0.9} height={17} />
          </div>
          <Skeleton duration={0.9} height={37} />
        </div>
      </div>
    </div>
  )
}
export default ProductCardSkeleton
