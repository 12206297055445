import { Area, MenuTypes, PromotionTypes } from '@/constants'
import type {
  BannerList,
  Brand,
  Category,
  KeywordsResponse,
  Path,
  Post,
  PostResponse,
  ProductCarouselResponse,
  ProductDetailResponse,
  ProductListResponse,
  ProductReviewResponse,
  PromotionDetailResponse,
  QueryParam,
  ReasonCancel,
  SystemConfig,
  UserAddress,
} from '@/types'
import {
  type FlashSale,
  type Menu,
  type Promotion,
  type Province,
  type Store,
} from '@/types'
import sortBy from 'lodash/sortBy'
import { api } from './api'
import { convertQueryParam } from './next-util'
import {
  toBanner,
  toBestSeller,
  toFlashSale,
  toFlashSales,
  toProductForYou,
  toProductTrending,
  toPromotions,
} from './transformer'

// type RequestParams = {
//   [key: string]: string | undefined
// }

export const getPromotions = async (): Promise<Promotion | null> => {
  const res = await api(`api/promotions?sortType=asc&page=1&limit=20`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toPromotions(res.data)
  }
  return null
}

export const getProductTrending = async (): Promise<Promotion | null> => {
  const res = await api(`api/promotions?&page=1&limit=20&type=trending`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toProductTrending(res.data)
  }
  return null
}

export const getProductForYou = async (): Promise<Promotion | null> => {
  const res = await api(
    `api/promotions?page=1&limit=20&productLimit=100&type=${PromotionTypes.PRODUCT_FOR_YOU}`,
    {
      baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return toProductForYou(res.data)
  }
  return null
}

export const getProductHot = async (): Promise<Promotion | null> => {
  const res = await api(
    `api/promotions?page=1&limit=20&type=${PromotionTypes.PRODUCT_HOT}`,
    {
      baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return toProductForYou(res.data)
  }
  return null
}

export const getBestSeller = async (): Promise<Promotion | null> => {
  const res = await api(
    `api/promotions?page=1&limit=20&type=${PromotionTypes.BEST_SELLER}`,
    {
      baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return toBestSeller(res.data)
  }
  return null
}
export const getFlashSale = async (): Promise<FlashSale | null> => {
  const res = await api(`api/flash-sales?page=1&limit=20`, {
    baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toFlashSales(res.data)
  }
  return null
}
export const getFlashSaleSSR = async (): Promise<FlashSale | null> => {
  const res = await api(`api/flash-sales?page=1&limit=20`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toFlashSales(res.data)
  }
  return null
}

export const getFlashSaleDetail = async (
  id: string
): Promise<FlashSale | null> => {
  const res = await api(`api/flash-sale/${id}`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toFlashSale(res.data as FlashSale)
  }
  return null
}

export const getPosts = async (): Promise<PostResponse | null> => {
  const res = await api(`api/posts?page=1&limit=20&type=news`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}

export const getStores = async (): Promise<Store[] | null> => {
  const res = await api(`api/store`, {
    baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return []
}
export const getStoresSSR = async (): Promise<Store[] | null> => {
  const res = await api(`api/store`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return []
}

export const getProvinces = async (): Promise<Province[] | null> => {
  const res = await api(`api/area?level=${Area.PROVINCE}`, {
    baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data.map((e: Province) => ({
      ...e,
      ...{ name: e.name.replace('Tỉnh ', '').replace('Thành phố ', '') },
    }))
  }

  return []
}
export const getProvincesSSR = async (): Promise<Province[] | null> => {
  const res = await api(`api/area?level=${Area.PROVINCE}`, {
    baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data.map((e: Province) => ({
      ...e,
      ...{ name: e.name.replace('Tỉnh ', '').replace('Thành phố ', '') },
    }))
  }

  return []
}

export const getMenus = async (): Promise<Menu[] | null> => {
  const res = await api(`api/menu-item?menuTypes=${MenuTypes.GLOBAL_TOP}`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data.map((e: Menu) => {
      e.children = sortBy(e.children, 'order')
      return e
    })
  }
  return []
}

export const getQuickMenu = async (): Promise<Menu[] | null> => {
  const res = await api(`api/menu-item?menuTypes=${MenuTypes.GLOBAL_QUICK}`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return sortBy(res.data, 'order')
  }
  return []
}

export const getSearchSuggestMenu = async (): Promise<Menu[] | null> => {
  const res = await api(`api/menu-item?menuTypes=${MenuTypes.SUGGEST_SEARCH}`, {
    baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return sortBy(res.data, 'order')
  }
  return []
}
export const getSearchSuggestMenuSSR = async (): Promise<Menu[] | null> => {
  const res = await api(`api/menu-item?menuTypes=${MenuTypes.SUGGEST_SEARCH}`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return sortBy(res.data, 'order')
  }
  return []
}

export const getCategoryMenu = async (): Promise<Menu[] | null> => {
  const res = await api(`api/menu-item?menuTypes=${MenuTypes.CATEGORY_MENU}`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data.map((e: Menu) => {
      e.children = sortBy(e.children, 'order')
      return e
    })
  }
  return []
}

export const getBanner = async (): Promise<BannerList[] | null> => {
  const res = await api(`api/banners`, {
    baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return toBanner(res.data)
  }
  return []
}

export const getCategory = async (
  id: string | string[],
  queryParam?: QueryParam
): Promise<Category | null> => {
  const params = convertQueryParam(queryParam)
  const res = await api(`api/product-cat/${id}/detail?${params}`, {
    baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    if (res.data?.properties) {
      res.data.properties = sortBy(res.data?.properties, 'order')
    }
    return res.data
  }
  return null
}
export const getProductWithPaginate = async (
  queryParams: QueryParam
): Promise<ProductListResponse | null> => {
  const params = convertQueryParam(queryParams)
  const res = await api(`api/products?${params}`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}
export const getProductWithPaginateSSR = async (
  queryParams: QueryParam
): Promise<ProductListResponse | null> => {
  const params = convertQueryParam(queryParams)
  const res = await api(`api/products?${params}`, {
    baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}

export const getConfig = async (): Promise<SystemConfig[] | null> => {
  const res = await api(`api/configuration`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return []
}

export const getPromotionDetail = async (
  id: string
): Promise<PromotionDetailResponse | null> => {
  const res = await api(`api/promotion/${id}`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }

  return null
}
export const getPostDetail = async (
  slug: string
): Promise<PromotionDetailResponse | null> => {
  const res = await api(`api/post/${slug}`, {
    baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }

  return null
}

export const getPageDetail = async (
  path: string[],
  queryParams?: QueryParam
): Promise<ProductDetailResponse | ProductListResponse | null> => {
  const params = convertQueryParam(queryParams)
  if (path.length > 1) {
    if (path[1].indexOf('c') > -1) {
      const res = await api(`api/products?${params}`, {
        baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
        method: 'get',
      })
      if (res && !(res instanceof Error)) {
        return res.data
      }
    }
    if (path[1].indexOf('p') > -1) {
      const res = await api(`api/post/${path[0]}`, {
        baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
        method: 'get',
      })
      if (res && !(res instanceof Error)) {
        return res
      }
    }
    if (path[1].indexOf('b') > -1) {
      const res = await api(`api/post/${path[0]}`, {
        baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
        method: 'get',
      })
      if (res && !(res instanceof Error)) {
        return res
      }
    }
    return null
  }

  const res = await api(`api/product/${path[0]}/detail?${params}`, {
    baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res
  }

  return null
}

export const getPath = async (path: string): Promise<Path | null> => {
  const res = await api(`api/path-mapping/parsing?path=${path}`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}
export const getBrand = async (): Promise<Brand[] | null> => {
  const res = await api(`api/property/brand`, {
    baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data.values
  }
  return null
}
export const getShippingAddress = async (): Promise<UserAddress[] | null> => {
  const res = await api(`api/user-contact?type=shipping`, {
    baseURL: process.env.NEXT_PRIVATE_IAM_API_URL,
    method: 'get',
  })

  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}
export const getShippingAddressDetail = async (
  params?: string
): Promise<UserAddress | null> => {
  const res = await api(`api/user-contact?ids=${params}&type=shipping`, {
    baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    method: 'get',
  })

  if (res && !(res instanceof Error)) {
    return res.data[0]
  }
  return null
}

export const getProductWithBrand = async (
  brand: string
): Promise<ProductListResponse | null> => {
  const res = await api(
    `api/products?property=thuong-hieu-brand:${brand}&page=1&limit=20`,
    {
      baseURL: process.env.NEXT_PRIVATE_CATALOG_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}
export const getReasonCancel = async (): Promise<ReasonCancel[] | null> => {
  const res = await api(`api/order/cancelled-order-reason?display=true`, {
    method: 'get',
    baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
  })

  if (res && !(res instanceof Error)) {
    return res.data
  }
  return null
}

export const getHomepageProductCarousel = async (): Promise<
  ProductCarouselResponse | undefined
> => {
  const res = await api(
    `api/promotions?sort=order,createdAt&page=1&limit=2&type=${PromotionTypes.LIST_HOT}`,
    {
      baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return res.data
  }
}
export const getHomepageProductCarouselSSR =
  async (): Promise<ProductCarouselResponse | null> => {
    const res = await api(
      `api/promotions?sort=order,createdAt&page=1&limit=2&type=${PromotionTypes.LIST_HOT}`,
      {
        baseURL: process.env.NEXT_PRIVATE_MARKETING_API_URL,
        method: 'get',
      }
    )
    if (res && !(res instanceof Error)) {
      return res.data
    }
    return null
  }

export const getProductReviews = async (
  params?: string
): Promise<ProductReviewResponse | null> => {
  const res = await api(`api/reviews?page=1&limit=5&${params}`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }

  return null
}
export const getAllPosts = async (): Promise<Post[] | null> => {
  const res = await api(`api/posts`, {
    baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data.data
  }
  return []
}
export const getAllCategory = async (): Promise<Category[] | null> => {
  const res = await api(`api/product-cat`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    if (res.data?.order) {
      res.data.order = sortBy(res.data.order, 'order')
    }
    return res.data
  }
  return null
}
export const getAllProductReview = async (
  params?: string
): Promise<ProductReviewResponse | null> => {
  const res = await api(`api/reviews?page=1&limit=50&${params}`, {
    baseURL: process.env.NEXT_PUBLIC_CATALOG_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return res.data
  }

  return null
}
export const getKeywordFooter = async (): Promise<KeywordsResponse | null> => {
  const res = await api(
    `api/keywords?type=footerKeyword&sortBy=order&sortType=asc&page=1&limit=40`,
    {
      baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return res.data.data
  }
  return null
}
export const getKeywordSearch = async (): Promise<KeywordsResponse | null> => {
  const res = await api(
    `api/keywords?type=footerKeyword&sortBy=order&sortType=asc&page=1&limit=40`,
    {
      baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
      method: 'get',
    }
  )
  if (res && !(res instanceof Error)) {
    return res.data.data
  }
  return null
}
export const getBottomMenu = async (): Promise<Menu[] | null> => {
  const res = await api(`api/menu-item?menuTypes=${MenuTypes.GLOBAL_BOTTOM}`, {
    baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    method: 'get',
  })
  if (res && !(res instanceof Error)) {
    return sortBy(res.data, 'order')
  }
  return []
}
